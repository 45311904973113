import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AuthService } from '../shared/auth.service';
import { ClinicService } from '../shared/clinic.service';
import { patientDetails, patientSurgery } from '../pages/patient/patient-details/patient-details.model';
import { patientAccessLevel } from '../model/generic.model';

@Injectable({
  providedIn: 'root'
})
export class FacilityManagementService {
  
  constructor(
    public authService: AuthService,
    public clinicService: ClinicService,
  ) { }

 
  public getCreatedFacilityName(patient:patientDetails){
   return patient.createdFacility?.name ?? '---'
  }
  
   //get primary Patient id
  private getPrimaryPatientId(patient:patientDetails){
    //return mrnvallue from patient api directly
    return patient.mrn ?? '---'
  }

   //get secondary Patient id
   private getSecondaryPatientId(patient:patientDetails,facilityId:string){
   // Filter the associatedMrns array to find the matching practiceOrg
   const filteredMrns = patient.associatedMrnMappings?.filter(mrn => mrn.practiceOrg === facilityId);
   // If a match is found, return the secondaryMrn, otherwise return empty string
   return filteredMrns?.length > 0 ? filteredMrns[0].secondaryMrn : '---';
  }

  //get patient relation ship enum with logged in account
  public getPatientAccessLevel(patient:patientDetails,facilityId:string){
    if (patient.practiceId && patient.practiceId === facilityId){
        //patient is owned by facility
        return patientAccessLevel.owner
    }
    //patient is not owned by facility, it might be viewer
    return patientAccessLevel.viewer
  }

  public getPatientAccessLevelFromPracticeOrg(practiceOrg:string,facilityId:string){
    if (practiceOrg && practiceOrg === facilityId){
        //patient is owned by facility
        return patientAccessLevel.owner
    }
    //patient is not owned by facility, it might be viewer
    return patientAccessLevel.viewer
  }

  //Get patient id set by viewing or logged in facility, to display in patient section of patient details
  public getCurrentFacilityPatientId(patient:patientDetails,facilityId:string){
   if (this.getPatientAccessLevel(patient,facilityId) === patientAccessLevel.owner){
    return this.getPrimaryPatientId(patient)
   } else {
    return this.getSecondaryPatientId(patient,facilityId)
   }
  }

  //Get patient id set by associated in facility, to display in surgery section of patient details
  public getAssociatedFacilityPatientId(patient:patientDetails,facilityId:string,surgeryDetails:patientSurgery){
    if(!patient || !facilityId || !surgeryDetails){
      return;
      }
    if (this.getPatientAccessLevel(patient,facilityId) === patientAccessLevel.owner){
      return this.getSecondaryPatientId(patient,this.getAssociatedFacilityId(surgeryDetails))
    } else {
      return this.getPrimaryPatientId(patient)
    }
   }
  
   
 private getAssociatedFacilityId(surgeryDetails:patientSurgery){
  //pass surgery and get associated facility id , associated facility will be one other than the viewing facility
    if (this.authService.isAscAccount){
         return surgeryDetails.clinic?.practiceOrg ?? ''
    }
    else {
      return surgeryDetails.asc?.practiceOrg ?? ''
    }
 }

 public getAssociatedFacilityPatientIdLabel(){
  return  (this.authService.isAscStaff ? "Clinic Patient ID" : "ASC Patient ID") 
 }

 //Get Clinic patient Id  for showing in dashboard excel export
 public getClinicPatientId(patient:any,surgery:any){
  if (this.authService.isClinicAccount){
   let accountFacilityID = this.authService.getPracticeOrg();
   return this.getCurrentFacilityPatientId(patient,accountFacilityID)
  }
  else {
   return this.getAssociatedFacilityPatientId(patient,this.authService.practiseOrg,surgery)
  }
 }

  //Get Asc patient Id  for showing in dashboard excel export
 public getASCPatientId(patient:any,surgery:any){
  if (this.authService.isAscAccount){
   let accountFacilityID = this.authService.getPracticeOrg();
   return this.getCurrentFacilityPatientId(patient,accountFacilityID)
  }
  else {
   return this.getAssociatedFacilityPatientId(patient,this.authService.practiseOrg,surgery)
  }
 }


}
