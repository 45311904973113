import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { AccountInactiveComponent } from './pages/account-inactive/account-inactive.component';
import { AccountPendingComponent } from './pages/account-pending/account-pending.component';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { AccountUnassignedComponent } from './pages/account-unassigned/account-unassigned.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PageaccessGuard } from './shared/pageaccess.guard';
import { AuthGuard } from './shared/auth.guard';
import { adiStorageKey, pagePermission } from './shared/constants/auth.constant';
import { CustomOktaCallbackComponent } from './pages/okta-callback/custom-okta-callback/custom-okta-callback.component';
import { RedirectPageComponent } from './pages/redirect-page/redirect-page.component';
import { AlconOrExperienceComponent } from './pages/or-experience/containers/alcon-or-experience/alcon-or-experience.component';
import { IolModelPreferencesComponent } from './pages/iol-model-preferences/containers/iol-model-preferences/iol-model-preferences.component';

const currentLocale= localStorage.getItem(adiStorageKey.lang) ?? 'en_US';

const routes: Routes = [
  { 
    path: '',
    redirectTo: `${currentLocale}/login/sso`,
    pathMatch: 'full'
  },
  {
    path: 'login', redirectTo: 'login/sso', pathMatch: 'full'
  },
  { path: 'login/callback', component: CustomOktaCallbackComponent, data: {isAdiOutside: true} },
  { path: `${currentLocale}/`, component: LoginComponent, data: {isAutomaticLogin: true}},
  { path: `${currentLocale}/redirect`, component: RedirectPageComponent, data: {isAdiOutside: true} },
  {
    path: ':locale',
    children: [
        { path: '', redirectTo: 'login/sso', pathMatch: 'full'},
        { path: 'login', component: LoginComponent},
        { path: 'login/sso', component: LoginComponent, data: {isAutomaticLogin: true}},
        { path: 'login/callback', component: CustomOktaCallbackComponent, data: {isAdiOutside: true} },
        { path: 'account-inactive', component: AccountInactiveComponent},
        { path: 'account-pending', component: AccountPendingComponent},
        { path: 'account-unassigned', component: AccountUnassignedComponent},
        { path: 'access-denied', component: AccessDeniedComponent},
        { path: 'home',component: HomeComponent}, 
        {
          path: 'homepage',
          loadChildren: () => import('./pages/home-page-dashboard/home-page-dashboard.module').then(m => m.HomePageDashboardModule)
        },
        { path: 'surgeries', 
          loadChildren: () => import(
          './pages/surgery/surgery.module'
          ).then((component)=>component.SurgeryModule)
        },
        { path: 'surgeons', 
          loadChildren: () => import(
          './pages/surgeon/surgeon.module'
          ).then((component)=>component.SurgeonModule)
        },
        { path: 'rooms', 
          loadComponent: ()=> import(
            './pages/operating-rooms/operating-rooms.component'
          ).then((component)=>component.OperatingRoomsComponent),
          canActivate: [AuthGuard, PageaccessGuard],
          data: {permission: pagePermission.rooms}
        },
        {
          path: 'patient-details/:patientId',
          loadComponent: ()=> import(
              './pages/patient/patient-details/patient-details.component'
            ).then((component)=>component.PatientDetailsComponent),
          canActivate: [AuthGuard, PageaccessGuard],
          data: {permission: pagePermission.surgeries}
        },
        {
          path: 'surgery-forms',
          loadComponent: ()=> import(
              './pages/surgery-forms/containers/surgery-forms-list.component'
            ).then((component)=>component.SurgeryFormsListComponent),
          canActivate: [AuthGuard, PageaccessGuard],
          data: {permission: pagePermission.forms}
        },
        { 
          path: 'or-experience/:surgeryId',
          component: AlconOrExperienceComponent, 
          canActivate: [AuthGuard, PageaccessGuard],
          data: {permission: 'surgeries'}
        },
        { 
          path: 'iol-model-preferences',
          component: IolModelPreferencesComponent, 
          canActivate: [AuthGuard, PageaccessGuard],
          data: {permission: pagePermission.iolModel}
        },
        { path: '**', component: PageNotFoundComponent}
      ]
    }
  ]
  

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }