import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, Output, AfterViewInit, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, Form, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertDialogComponent } from '../../../components/alert-dialog/alert-dialog.component';
import { AuthService } from '../../../shared/auth.service';
import { ClinicService } from '../../../shared/clinic.service';
import { noCodeValidator, phoneNumberValidator } from '../../../shared/no-code-validator.service';
import { SurgeonService } from '../../../shared/surgeon.service';
import { SurgeryService } from '../../../shared/surgery.service';
import { AlconToastMessageService } from 'src/app/shared/alcon-toast-message.service';
import { MatStepper } from '@angular/material/stepper';
import { DatePipe, formatDate } from '@angular/common';
import { isBefore, isValid } from 'date-fns';
import { Subscription } from 'rxjs';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_NATIVE_DATE_FORMATS } from '@angular/material/core';
import { CustomDateAdapter } from 'src/app/shared/alcon-date-adapter/alcon-date-adapter';
import { Platform } from '@angular/cdk/platform';
import { SessionStorageService } from 'src/app/shared/session-storage.service';
import { appEvents, patientAccessLevel } from 'src/app/model/generic.model';
import { NavigationService } from 'src/app/shared/service/navigation.service';
import { PatientGraphqlService } from 'src/app/shared/service/alcon-graphql/patient-graphql.service';
import { PatientService } from 'src/app/services/patient/patient.service';
import { patientDetails } from '../../patient/patient-details/patient-details.model';
import { patientGenderList, patientModelTab } from 'src/app/shared/model/patient.model';
import { BannerTypes } from 'src/app/shared/model/banner.model';
import { emailRegex } from 'src/app/shared/constants/constants';
import { UtilityService } from 'src/app/shared/service/utility.service';
import { signLanguageList } from 'src/app/shared/constants/constants';
import { FacilityManagementService } from 'src/app/services/facility-management.service';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {displayDefaultIndicatorType: false},
    },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_NATIVE_DATE_FORMATS },
  ],
})
export class PatientComponent implements OnInit, OnDestroy, AfterViewInit {

  @Output() successEvent = new EventEmitter<any>();
  patientTitleList: string[] = [];
  errorMessage: string = '';
  accountFacilityID: string = ''
  accountFacilityName: string = ''
  patientFullName: string = '';
  patientDetailsForm!: FormGroup;
  patientSubmitted = false;
  isPatientCreated:boolean = false;
  isRecordData:boolean = false;
  patient_full_name: string = ''
  patient_dob: string = ''
  patientID:any;
  surgeryID:any;
  practiceOrg:any;
  record: any           = [];
  PastSurgeries : any   = []
  FutureSurgeries : any = []
  today = new Date();
  isExistingPatient : boolean = false
  invaliddate : boolean = false
  invaliddatemsg: string = ''
  tabOne: string = '<b>01</b> Patient';
  showStep = false;
  isChecked: boolean = false;
  isPatientAdded: boolean = true;
  stepperIcon: string = "assets/step-circle.svg"
  selectedIndex = 0;
  isIOLTab: boolean = true;
  isSurgerySubmitted: boolean = false;
  previous_mrn: any = '';
  existing_patient_mrn: any = ''
  isMrnCheckLoading: boolean = false;
  public appEvents = appEvents
  firstNameValue = ''
  lastNameValue = ''

  @ViewChild('h1') h1: ElementRef<any> | undefined;
  @ViewChild("stepper", { static: false }) stepper!: MatStepper;
  patientData: any = {};
  validationText = '';
  private subscription = new Subscription();
  private addIolSub = new Subscription();
  private tabClickSub = new Subscription();

  patientInfo = "Add new patient";
  isAddPatient = true;
  surgeryLoader: boolean = false;
  patientLoader = false;
  stepOneHeader = 'Patient info';
  isExistingPatientAddSurgeryFlow: boolean = false;
  prefferedLangList:any= [];
  showPatientExistsError = false;
  

  showPatientList = false;
  fromPatientDetail = false;
  showPatientOnly = false;
  patientGenderList = patientGenderList;
  countryCodeList = [{
    "name": "United States",
    "dial_code": "+1",
    "code": "US"
  }];
  patientPreferedTimeOfDay = [
    { label: 'Early', value: 'Early' },
    { label: 'Middle', value: 'Middle' },
    { label: 'Late', value: 'Late' },
  ]

  hidePatientInfo = false;
  fromIOLOnly = false;
  fromEditSurgery = false;
  fromCloneSurgery = false;
  initialFormValues: any;
  showPrimaryfieldUpdate = false;
  bannerTypes = BannerTypes;
  surgeonFormValid = false;
  doSubmit: {from: string, submit: boolean, targetTab?: string} = {from : '', submit: false, targetTab: ''};
  fromStepper = '';
  currentTabValue = '';
  patientFormChange = false;
  isPatientIDEdited = false;
  isPrimaryFieldEdited = false;
  signLanguageList = signLanguageList;

  constructor(
    public fb: FormBuilder,
    public service: SurgeryService,
    public authservice: AuthService,
    public router: Router,
    public clinicservice: ClinicService,
    public surgeonservice: SurgeonService,
    private cdRef:ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public myElement: ElementRef,
    private dialog: MatDialog,
    public toastService: AlconToastMessageService,
    public dialogRef: MatDialogRef<PatientComponent>,
    private datePipe: DatePipe,
    private elementRef: ElementRef,
    private sessionStorageService: SessionStorageService,
    public navigationService: NavigationService,
    private patientService: PatientService,
    public patientSearchGraphqService: PatientGraphqlService,
    private utilityService: UtilityService,
    private facilityService:FacilityManagementService

    ) {
      this.accountFacilityID       = this.authservice.getPracticeOrg()
      this.accountFacilityName     = this.authservice.facilityName;
      this.service.procedureChanged.subscribe((data => {
        this.isIOLTab = data;
      }));
      this.addIolSub = this.service.addIOLBtnClicked.subscribe((data => {
        if(data) {
          this.isSurgerySubmitted = data;
          if(this.isSurgerySubmitted)
            this.scrollToTop();
            this.stepper.next();
            this.doSubmit = {from : '', submit: false};
        }
      }));
      this.tabClickSub = this.service.patientModelTabClicked.subscribe((data => {
        if(data) {
          this.changeTab();
        }
      }));
  }

  ngAfterViewChecked()
  {
    this.cdRef.detectChanges();
  }
  ngAfterViewInit() {
    this.scrollToTop();
    this.currentTab();
  }

 
  ngOnInit(): void
  {
    if(this.data.fromCloneSurgery){
      this.hidePatientInfo = true;
      this.patientSubmitted = true;
      this.surgeryLoader = true;
      this.fromCloneSurgery = true;
    }
    
    this.getTitles();
    this.getPrefferedLangData();
    this.patientFormValidation();
    this.validationText='';
    if(this.data.selectedIndex && this.data.selectedIndex !== '' || ((this.data.fromEditSurgery || this.data.showSurgery) && this.selectedIndex == 0)){
      this.selectedIndex = this.data.selectedIndex;
      this.patientSubmitted = true;
      this.patientData = this.service.patientData;
      if(this.patientData.id && this.patientData.patientID && this.patientData.practiceOrg) {
        this.data.id = this.patientData.id;
        this.data.patientId = this.patientData.patientID;
        this.data.practice_org = this.patientData.practiceOrg;
        this.data.practiceOrg=this.patientData.practiceOrg;

      }
      if(this.selectedIndex === 2) {
        this.surgeryLoader = true;
        this.isSurgerySubmitted = true;
        if(!(this.data.id && this.data.id != ''))
        {
          if(this.patientData.patientID && this.patientData.practiceOrg){
            this.data.practice_org=this.patientData.practiceOrg;
            this.data.practiceOrg=this.patientData.practiceOrg;

            this.data.patientId=this.patientData.patientID;
            this.data.id = this.service.surgeryData.surgeryID;

          }
        }

      }
      if(this.selectedIndex === 1) {
        this.surgeryLoader = true;
        this.patientSubmitted = true;
        if(!(this.data.id && this.data.id != ''))
        {
          if(this.patientData.patientID && this.patientData.practiceOrg){
            this.data.practice_org=this.patientData.practiceOrg;
            this.data.practiceOrg=this.patientData.practiceOrg;

            this.data.patientId=this.patientData.patientID;
            this.patientID=this.data.patientId
            this.practiceOrg=this.patientData.practiceOrg;

            this.isAddPatient = false;
            this.getDetails();

          }
        }
      }
    }
    if(this.data.id && this.data.id != '')
    {
      this.surgeryID = this.data.id
      this.practiceOrg=this.data.practice_org ? this.data.practice_org : this.data.practiceOrg;
      this.patientID=this.data.patientId
      this.isAddPatient = false;
      this.isSurgerySubmitted = true;
      if(this.fromCloneSurgery){
        this.patientInfo = "Clone surgery"
      }else{
        this.patientInfo = this.data.showIOL || this.data.fromEditSurgery ? "Edit surgery details":"Edit patient and surgery information";
      }      
      this.getDetails();
    }
    if(this.data.fromPatientDetail){
      this.fromPatientDetail = true;
      if(this.data.showSurgery){
        this.hidePatientInfo = true;
      }
    }
  
    if(this.data.showPatientOnly){
      this.showPatientOnly = true;
    }
    if(this.data.showIOL){
      this.hidePatientInfo = true;
      this.isIOLTab = true;
      this.isSurgerySubmitted = true;
      this.fromIOLOnly = true;
    }
    if(this.data.fromEditSurgery){
      this.hidePatientInfo = true;
      this.patientSubmitted = true;
      this.surgeryLoader = true;
      this.isSurgerySubmitted = true;
      this.fromEditSurgery = true;
    }

    this.service.inlineDisplay.subscribe((value) => {
      if(value) {
        this.practiceOrg = this.service.existingPatientData.practiceId
        this.patientID = this.service.existingPatientData.patientId
        if(this.patientID){
          this.isAddPatient = false;
        }
        this.patientInfo = this.data.fromPatientDetail ? 'Add new surgery': 'Add new surgery to an existing patient';
        this.stepOneHeader = 'Review Patient';
        this.isExistingPatientAddSurgeryFlow = true;
        // this.patchPatientData(this.service.existingPatientData);
        this.getDetails();
        if(this.showPatientOnly){
          this.patientInfo = 'Edit patient information';
        }
      }
    });

    this.patientDetailsForm.get('patientMrn')?.valueChanges.subscribe((newValue)=>{
      if(newValue!=this.previous_mrn){
        this.previous_mrn=""
      }
    })
    this.patientDetailsForm.valueChanges.subscribe(values => {
      this.patientFormChange = true;
    });

  }

  getSurgeryLoader(event: any) {
    this.surgeryLoader = event;
  }

  getTitles() {
    this.authservice.getTitles().subscribe({
      next: (res: any) => {
        if(res.statusCode === 200) {
          this.patientTitleList = res.data;
        } else {
          this.errorAlertDialog(res.message)
        }
      },
      error: (err: any) => {
        this.errorAlertDialog(err);
      }
    }
    );
  }

  private patientFormValidation() {
    const date = !this.isAddPatient
    ? new Date(this.patientDetailsForm.get('tempdateOfBirth')?.value)
    : "";
    this.patientDetailsForm = this.fb.group({
      practiceOrg: [this.accountFacilityID],
      title: [''],
      gender: ['', Validators.required],
      firstName: ['', [Validators.required, noCodeValidator(), Validators.maxLength(50)]],
      middleName: ['', [noCodeValidator(), Validators.maxLength(50)]],
      lastName: ['', [Validators.required, noCodeValidator(), Validators.maxLength(50)]],
      tempdateOfBirth: [date, [
        Validators.required,
        this.validateDate.bind(this),
        this.maxDobValidate.bind(this)
      ]],
      dateOfBirth: [''],
      phoneNumberCountryCode: ['+1'],
      phoneNumber: ['', [Validators.minLength(10), Validators.maxLength(15), phoneNumberValidator()]],
      alternateContactName: ['', [Validators.maxLength(50)]],
      alternatePhoneNumber: ['', [Validators.maxLength(15), phoneNumberValidator()]],
      email: ['', [this.emailValidator.bind(this)]],
      patientMrn: ['', [Validators.required, Validators.maxLength(50), noCodeValidator()]],
      diabetesStatus: [''],
      allergies: ['', [Validators.maxLength(200), noCodeValidator()]],
      languagesSpoken: ['', [noCodeValidator()]],
      translatorNeeded: [''],
      patientTimeOfDayPreference: [''],
      comments: ['', [Validators.maxLength(300), noCodeValidator()]],
      preferredLanguage: this.fb.group({
        code: [''],
        display: ['']
      }),
      associatedMrnMappings: this.fb.group([{
          practiceOrg: [''],
          secondaryMrn: ['']
      }]),
      signLanguage: ['Not Needed']
    });
  }

  getDetails()
  {
    this.isPatientCreated = true
      this.patientLoader = true;
      this.patientService.getPatientDetails(this.patientID).subscribe({
        next: (res: patientDetails) => {
        if (res)
            {
              this.record = res;
              const basicCatractInfo = this.service.checkCatractSurgery(this.record, this.surgeryID);
              this.data.basicCatractInfo = basicCatractInfo;
              this.data.exchangeSurgery = this.service.checkExchangeSurgery(this.record, this.surgeryID);
              this.service.patientData = {id: this.surgeryID, patientID: this.patientID, practiceOrg: this.practiceOrg, obj: this.record};
              this.service.patientResponse = this.record;
              this.isRecordData       = true
              this.patient_full_name  = this.record.firstName+' '+this.record.lastName
              this.patient_dob        = formatDate(this.record.birthDate,'yyyy-MM-dd',"en-US");
              this.patientID  = this.record.patientId
              let temp_patient_dob = this.record.birthDate;
              if (this.record.birthDate) {
                  temp_patient_dob = new Date(this.record.birthDate);
                  temp_patient_dob.setMinutes(temp_patient_dob.getMinutes() + temp_patient_dob.getTimezoneOffset());
              }
              this.setLanguageData();

              if(this.facilityService.getPatientAccessLevel(this.record,this.authservice.getPracticeOrg()) !== patientAccessLevel.owner) {
                  this.patientDetailsForm.get('patientMrn')?.setValidators(null);
              } else
              {
                 this.patientDetailsForm.get('patientMrn')?.setValidators(Validators.required);
              }

              this.patientDetailsForm.patchValue({
                  title: this.record.prefix ? this.getPrefix(this.record.prefix) : '',
                  firstName: this.record.firstName,
                  middleName: this.record.middleName,
                  lastName: this.record.lastName,
                  tempdateOfBirth: temp_patient_dob,
                  dateOfBirth: this.patient_dob,
                  gender: this.record.gender,
                  phoneNumber: this.record.phone,
                  alternateContactName: this.record.additionalInfo.alternateContactName,
                  alternatePhoneNumber: this.record.additionalInfo.alternatePhoneNumber,
                  phoneNumberCountryCode: this.record.additionalInfo.phoneNumberCountryCode ?? '+1',
                  email: this.record.email,
                  patientMrn: this.facilityService.getCurrentFacilityPatientId(this.record,this.authservice.getPracticeOrg()),
                  practiceOrg: this.record.practiceId,
                  diabetesStatus: this.record.additionalInfo.diabetesStatus,
                  allergies: this.record.additionalInfo.allergyOther ? (Array.isArray(this.record.additionalInfo.allergyOther) ? this.record.additionalInfo.allergyOther.join(', ') : this.record.additionalInfo.allergyOther) : '',
                  languagesSpoken: this.record.languageCode, 
                  translatorNeeded: this.record.additionalInfo.translatorNeeded,
                  patientTimeOfDayPreference: this.record.additionalInfo.patientTimeOfDayPreference,
                  comments: this.record.additionalInfo.comments,
                  signLanguage: this.record.additionalInfo.signLanguage
                  
                });
                this.patientFullName = this.record.firstName+' '+this.record.lastName
                  this.previous_mrn=this.record.mrn
                  this.existing_patient_mrn=this.record.mrn;
                  this.initialFormValues = this.patientDetailsForm.value;

            }
            this.patientFormChange = false;
            this.patientLoader = false;
          },
          error: (error) => {
            this.patientLoader = false;
          }
      })

  }

 validatePatientMrn(event:any) {
  let current_mrn = event.target.value
  if(current_mrn==this.previous_mrn || (!this.isAddPatient && this.existing_patient_mrn==current_mrn)){
    return
  }else{
    this.previous_mrn=current_mrn
  }
  this.patientDetailsForm.patchValue({"patientMrn":current_mrn})
  let payload: any = {
    patientMRN: current_mrn,
    practiceOrg: this.authservice.getPracticeOrg(),
  };
  this.isMrnCheckLoading=true

    this.patientSearchGraphqService.searchPatientMrnGraphQuery(payload).subscribe({
      next:(res: any)=>
      {
        if (res && (res.data.patientResponse.totalCount !== 0))
        {           
          if(res.data.patientResponse.totalCount > 0) {
            this.patientDetailsForm.get('patientMrn')?.setErrors({duplicate:true})
            this.isMrnCheckLoading=false
          } 
        } else if (res.data.patientResponse.totalCount === 0) {
          this.isMrnCheckLoading=false
        }
      },
      error: (err: any) =>
      {
        this.isMrnCheckLoading=false
      },
    });
}

  openAlertDialog(msg:any) {
    const dialogRef = this.dialog.open(AlertDialogComponent,{
      data:{
        message: msg,
        buttonText: {
          cancel: 'Ok'
        }
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
        this.scrollToTop()
        this.successEvent.emit();
    });

  }

  closePopup(event?: string)
  {
    this.dialogRef.close(event);
  }

  get patientField()
  {
    return this.patientDetailsForm.controls;
  }


  dateFormatMMDDYYYY(obj:string)
  {
    let newdate   = obj.split("-")
    let y         = newdate[0]
    let m         = newdate[1]
    let d         = newdate[2]
    let validdate = m+d+y
    return validdate
  }

  isDateValid(dateStr:string)
  {
    return new Date(dateStr);
  }

  changeDateFormat(obj:string)
  {
    try
    {
      if (obj)
        obj = obj.replace('/', '');

      if (obj)
        obj = obj.replace('-', '');

      let y = obj.slice(-4)
      let m = obj.slice(0, 2)
      let d = obj.substring(2, 4)

      if(isNaN(Number(y)) || isNaN(Number(m)) || isNaN(Number(d)))
      {
        return false
      }

      let newdate = y+'-'+m+'-'+d
      let timestamp = Date.parse(newdate);
      if (isNaN(timestamp))
      {
        return false
      }
      else
      {
        return newdate
      }
    }
    catch(e)
    {
      return false
    }
  }
  patientInfoSubmit(type: string)
  {
    if (this.firstNameValue){
      this.patientFullName = this.firstNameValue+' '+this.lastNameValue
    }
    let phone = this.patientDetailsForm.value.phoneNumber
    if(phone)
    {
      if(phone.length == 2)
      {
        this.patientDetailsForm.patchValue({
          phoneNumber: ''
        });
      }
    }
    // Removing white spaces from both sides of a string
    this.patientDetailsForm.patchValue({firstName: this.patientDetailsForm.value.firstName.trim()});
    this.patientDetailsForm.patchValue({lastName: this.patientDetailsForm.value.lastName.trim()});
    this.patientDetailsForm.patchValue({patientMrn: this.patientDetailsForm.value.patientMrn.trim()});

    if(this.patientDetailsForm.value.tempdateOfBirth)
    {
      let tempdateOfBirth = this.patientDetailsForm.value.tempdateOfBirth;

      if(tempdateOfBirth.length < 8)
      {
        this.invaliddate = true
        this.invaliddatemsg = 'Invalid Date of Birth'
        return;
      }
      let dateOfBirth = this.datePipe.transform(tempdateOfBirth, 'yyyy-MM-dd');
      this.invaliddate    = false
      this.invaliddatemsg = ''
      this.patientDetailsForm.patchValue({dateOfBirth: dateOfBirth});
    }

    if(this.isPatientCreated)
    {

      if(this.formHasChanged()){
      //update patient information
        this.surgeryLoader = true;
        this.setLanguageData();
        this.patientDetailsForm.patchValue({
          allergies: this.patientDetailsForm.value.allergies
        });
        delete this.patientDetailsForm.value['tempdateOfBirth'];
        this.addMrn()
        this.service.patient_update(this.patientID, this.patientDetailsForm.value).subscribe({
          next: (res: any) => {
            if (res)
            {
              //this.patientDetailsForm.patchValue({practiceOrg: this.accountFacilityID})
              this.errorMessage   = ''
              this.patient_full_name = this.patientDetailsForm.value.firstName+' '+this.patientDetailsForm.value.lastName
              this.patient_dob       = this.patientDetailsForm.value.dateOfBirth;
              this.patientSubmitted = true;
              this.initialFormValues = this.patientDetailsForm.value;
              this.showPatientExistsError = false;
              this.previous_mrn=this.patientDetailsForm.value.patientMrn;
              this.existing_patient_mrn=this.patientDetailsForm.value.patientMrn;
              this.isAddPatient = false;
              this.isPatientIDEdited = false;
              this.isPrimaryFieldEdited = false;
              
              if(type === 'save'){
                this.surgeryLoader = false;
                this.service.patientData = {id: this.surgeryID, patientID: this.patientID, practiceOrg: this.practiceOrg, obj: {}};
                this.closePopup(appEvents.reloadData);
                const message = 'Patient updated successfully.';
                const linkText = this.isExistingPatientAddSurgeryFlow ? 'Add surgery details' : 'Edit surgery details';
                this.toastService.showToastMsg(
                message,
                linkText,
                'save-patient',
                "Success",
                "Success".toLocaleLowerCase()
                );
              } else {
                if(this.showPatientOnly){
                  this.toastService.showToastMsg(
                    "Patient information updated",
                    "",
                    'save-patient',
                    "Success",
                    "Success".toLocaleLowerCase()
                    );
                    this.closePopup(appEvents.reloadData);
                }else {
                  this.patientData = {id: this.surgeryID, patientID: this.patientID, practiceOrg: this.practiceOrg};

                  if(this.patientData.patientID) {
                    if(type !== 'add-tab'){
                      this.scrollToTop();
                      this.stepper.next();
                      this.doSubmit = {from : '', submit: false};

                    }else{
                     
                      this.changeTab();
                    }
                  }
              }
              }
            }
            else
            {
              this.surgeryLoader = false;
              this.patientSubmitted = false;
            }
          },
          error: (err: any) => {
            this.surgeryLoader = false;
            this.patientSubmitted = false;
            this.errorAlertDialog(err)
          },
        });
    }else{
      if(this.showPatientOnly){
        this.closePopup();
      }
      if(type === 'save'){
        this.surgeryLoader = false;
        this.service.patientData = {id: this.surgeryID, patientID: this.patientID, practiceOrg: this.practiceOrg, obj: {}};
        this.closePopup(appEvents.reloadData);
      }
      this.patientDetailsForm.patchValue({practiceOrg: this.accountFacilityID})
      this.errorMessage   = ''
      this.patient_full_name = this.patientDetailsForm.value.firstName+' '+this.patientDetailsForm.value.lastName
      this.patient_dob       = this.patientDetailsForm.value.dateOfBirth;
      this.patientSubmitted = true;
  
      this.patientData = {id: this.surgeryID, patientID: this.patientID, practiceOrg: this.practiceOrg};
      
      if(this.patientData.patientID) {
        if(type !== 'add-tab'){

        this.scrollToTop();
        this.stepper.next();
        this.doSubmit = {from : '', submit: false};
        }else{
          this.changeTab();

        }

      }
      
    }
    }
    else
    {
      //create patient
      delete this.patientDetailsForm.value['associatedMrnMappings'];
      this.surgeryLoader = true;
      this.setLanguageData();
      delete this.patientDetailsForm.value['tempdateOfBirth'];
      this.service.patient_create(this.patientDetailsForm.value).subscribe({
        next: (res: any) =>
        {
          if (res)
          {
            this.errorMessage     = ''
            this.isRecordData     = true
            this.patientID        = res.data.patientId
            this.practiceOrg      = this.accountFacilityID;
            this.patient_full_name = this.patientDetailsForm.value.firstName+' '+this.patientDetailsForm.value.lastName
            this.patientSubmitted = true;
            this.initialFormValues = this.patientDetailsForm.value;
            this.showPatientExistsError = false;
            this.previous_mrn=this.patientDetailsForm.value.patientMrn;
            this.existing_patient_mrn=this.patientDetailsForm.value.patientMrn;
            this.isAddPatient = false;

            this.isPatientCreated = true;
              if(type === 'save'){
                this.surgeryLoader = false;
                this.service.patientData = {id: '', patientID: this.patientID, practiceOrg: this.practiceOrg, obj: {}};
                this.service.addSurgeryFlow = false;
                this.closePopup();
                this.navigationService.navigateTo(['surgeries']);
                const message = 'Patient created successfully.';
                const linkText = 'Add surgery details';
                this.toastService.showToastMsg(
                message,
                linkText,
                'save-patient',
                "Success",
                "Success".toLocaleLowerCase()
                );
              } else {
                this.patientData = {id: '', patientID: this.patientID, practiceOrg: this.practiceOrg};
                this.initialFormValues = this.patientDetailsForm.value;

                if(this.patientData.patientID) {
                  if(type !== 'add-tab'){
                    this.scrollToTop();
                    this.stepper.next();
                    this.doSubmit = {from : '', submit: false};

                  }else{
                    this.changeTab();
                  }
                }
              }

          }
          else
          {
            this.surgeryLoader = false;
            this.patientSubmitted = false;
          }
        },
        error: (err: any) => {
          if(err && err.data?.isExistingPatient) {
            this.showPatientExistsError = true;
            this.scrollToTopWithoutDelay();

          } else{

            this.errorAlertDialog(err)
          }
          this.surgeryLoader = false;
          this.patientSubmitted = false;
        },
      });
    }
  }

  addMrn(){
    if(this.facilityService.getPatientAccessLevel(this.record,this.authservice.getPracticeOrg()) === patientAccessLevel.owner) {
      delete this.patientDetailsForm.value['associatedMrnMappings'];
      this.patientDetailsForm.patchValue({patientMrn: this.patientDetailsForm.value.patientMrn.trim()});
   } else
   {
    const associatedMrnObject = [
      {
        practiceOrg: this.authservice.getPracticeOrg(),
        secondaryMrn: this.patientDetailsForm.value.patientMrn.trim()
      }
    ]
    this.patientDetailsForm.patchValue({
      associatedMrnMappings: associatedMrnObject
    });
   }

  }
  getPrefix(prefix: string) {
    return prefix.replace(/[\[\]]/g, '');
  }

  private setLanguageData() {
    if (this.patientDetailsForm.value.languagesSpoken) {
      const selectedLanguage = this.prefferedLangList.find((language: { code: any; }) => language.code === this.patientDetailsForm.value.languagesSpoken);

      const preferredLanguage = {
        code: selectedLanguage.code,
        display: selectedLanguage.display
      };
      this.patientDetailsForm.patchValue({
        preferredLanguage: preferredLanguage
      });
    }
  }

  errorAlertDialog(msg:any) {
    const dialogRef = this.dialog.open(AlertDialogComponent,{
      data:{
        message: msg,
        buttonText: {
          cancel: 'Ok'
        }
      },
    });
  }

  AlertDialog(msg:any) {
    const dialogRef = this.dialog.open(AlertDialogComponent,{
      data:{
        message: msg,
        buttonText: {
          cancel: 'Ok'
        }
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.scrollToTop()
    });

  }

  isValidDate(date: Date | string): boolean
  {
    let current_date = new Date()
    let surgery_date = new Date(date)
    if(surgery_date <= current_date)
    {
      return true
    }
    else
    {
      return false
    }
  }

  scrollToTop(): void
  {
    const activeTabInterval = setInterval(() => {
      const nativeElement = this.elementRef.nativeElement;
      const activeTab = nativeElement.querySelector('.alcon-mcc-patient-container .alc_page-header-cta');
      if(activeTab) {
        clearInterval(activeTabInterval);
        activeTab.scrollIntoView({block: 'center', behavior: 'smooth'});
      }
    }, 1000);
    
    // this.h1?.nativeElement.scrollIntoView({behavior: 'smooth'});
  }

  getTodaysDate(){
    let today= new Date()
    today.setHours(0,0,0,0)
    return today
  }

  getMinDate(){
    return this.utilityService.getDateWithZeroHours("1920-01-01");
  }

  validateDate(control: FormControl) {
    const enteredDate = new Date(control.value);
    const isValidDate = isValid(enteredDate);
    return isValidDate ? null : { invalidDate: true };  
  }

  maxDobValidate(control: FormControl) {
    const todayDate = new Date();
    const isDateLessThanToday = 
      isValid(control.value) && isBefore(new Date(control.value), todayDate);
    if (!isValid(control.value)) {
      this.validationText = 'Enter a valid date of birth (mm/dd/yyyy).';
    } else if (!isDateLessThanToday) {
      this.validationText = 'Date of birth cannot be in the future';
    } else{
      this.validationText = '';
    }
    return isDateLessThanToday ? null : { invalidDate: true };
  }

  isDateFormat(inp:string): boolean{
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;
    //const regex = /^((0?[1-9]|1[012])[\/]*(0?[1-9]|[12][0-9]|3[01])[\/]*[0-9]{4})*$/
    return regex.test(inp);
  }

  addPatientDobClear(event: MouseEvent) {
    event.stopPropagation();
    this.patientDetailsForm.get('tempdateOfBirth')?.setErrors(null);
    this.validationText = '';
    this.invaliddatemsg = '' ;
    this.patientDetailsForm.patchValue({tempdateOfBirth: ''});
  }

  onInput(event: any) {
    let inputValue: string = event.target.value;
    inputValue = inputValue.replace(/\D/g, '');
    if (inputValue.length > 2 && inputValue.length < 4 ) {
      inputValue = inputValue.replace(/^(\d{2})(\d{0,2})/, '$1/$2');
    }
    if (inputValue.length > 5) {
      inputValue = inputValue.replace(/^(\d{2})(\d{2})(\d{0,4})/, '$1/$2/$3');
    }
    event.target.value = inputValue;
  }
  backToSearch() {
     this.subscription.add(
      this.service.setInlineDisplay(false)
    );
  }
  ngOnDestroy() {
    // Reset the state when this component is destroyed
    this.service.setInlineDisplay(false);
    if(this.subscription){ this.subscription.unsubscribe();}
    this.addIolSub.unsubscribe();
    this.tabClickSub.unsubscribe();
    this.surgeonservice.clearSurgeonPreferenceCollection();
  }

  getPrefferedLangData()
  {
    this.service.getPrefferedLanguages().subscribe({
      next: (res: any) =>
      {
        if (res)
        {
          this.prefferedLangList = res.data?.filter((lang: { code: string; }) => !lang?.code?.startsWith("en"));
        }
      },
      error: (err: any) => {
        this.errorAlertDialog(err)
      }
    })
  }

  emailValidator(control: FormControl){
    const email = control.value;
    if(!email){
      return null;
    }
    if(email.length > 50){
      return{'maxLength' : true};
    }

    if(!emailRegex.test(email)){
      return{'pattern' : true};
    }
    return null;
  }

  showPatientsList(){
    this.showPatientList = true;
  }

  backToAddPage(){
    this.showPatientList = false;
    this.showPatientExistsError = false;
  }

  scrollToTopWithoutDelay(): void
  {
      const nativeElement = this.elementRef.nativeElement;
      const activeTab = nativeElement.querySelector('.alcon-mcc-patient-container .alc_page-header-cta');
      if(activeTab) {
        activeTab.scrollIntoView({block: 'center', behavior: 'smooth'});
      }
  }

  currentTab(){
    if(this.stepper?.selectedIndex || this.stepper?.selectedIndex === 0){
      if(this.hidePatientInfo){
        this.currentTabValue = this.stepper.selectedIndex === 0 ? patientModelTab.surgery : patientModelTab.iol;
      }else{
        this.currentTabValue = this.stepper.selectedIndex === 0 ? patientModelTab.patient : (this.stepper.selectedIndex === 1 ? patientModelTab.surgery : patientModelTab.iol);
      }
    }else{
      this.currentTabValue = '';
    }
  }
  call(){
    this.currentTab();
  }
  moveNext(next: boolean, fromStepper: string, event: any){
    event.stopPropagation();

    this.currentTab();
    if(fromStepper){
      this.fromStepper = fromStepper;
    }
     if(this.currentTabValue !== fromStepper){
        if(this.currentTabValue === patientModelTab.surgery){
          this.doSubmit = {from: patientModelTab.surgery, submit: true, targetTab: fromStepper};
         
        }else if(this.currentTabValue === patientModelTab.iol){
          this.doSubmit = {from: patientModelTab.iol, submit: true, targetTab: fromStepper};
          
        }else if(this.currentTabValue === patientModelTab.patient){
          this.patientInfoSubmit('add-tab');
         
        }
      }
  }
  changeTab(){
    this.doSubmit = {from : '', submit: false};
    if(this.fromStepper === patientModelTab.patient){
      setTimeout(()=>{
        this.scrollToTop();
        this.stepper.selectedIndex = 0;
      });
    }else if(this.fromStepper === patientModelTab.surgery){
      setTimeout(()=>{
        this.scrollToTop();
        this.stepper.selectedIndex = this.hidePatientInfo? 0 : 1;
      });
    }else if(this.fromStepper === patientModelTab.iol){
      setTimeout(()=>{
        this.scrollToTop();
        this.stepper.selectedIndex = this.hidePatientInfo ? 1 : 2;
      });
    }
  }
  
  
  getLabelByUserRole(): string {
    if(this.authservice.isClinicStaff) {return "Clinic"}
    else if(this.authservice.isAscStaff) {return "ASC"}
    else return "";
  }

  formHasChanged(): boolean {
    return Object.entries(this.initialFormValues).some(
      ([field, value]) => value !== this.patientDetailsForm.value[field]
    );
  }

  checkIsAnyPrimaryFieldEdited(){
    this.isPrimaryFieldEdited = this.anyPrimaryFieldUpdated();
    this.hidePatientExistsError();
  }

  private hidePatientExistsError(){ 
    this.showPatientExistsError = false;
  }

  checkIsPatientIDEdited(){
    if(this.initialFormValues && this.initialFormValues.patientMrn !== this.patientDetailsForm.value['patientMrn']){
        this.isPatientIDEdited = true;
        return;   
  }
  this.isPatientIDEdited = false;
  }
  
  anyPrimaryFieldUpdated(){
   if  (this.initialFormValues) {
    if(this.initialFormValues.firstName !== this.patientDetailsForm.value['firstName'] ||
    this.initialFormValues.lastName !== this.patientDetailsForm.value['lastName'] ||
    !this.service.toTestDateEqual(this.initialFormValues.tempdateOfBirth ? this.initialFormValues.tempdateOfBirth : this.initialFormValues.dateOfBirth, this.patientDetailsForm.value['tempdateOfBirth']) ||
    this.initialFormValues.gender !== this.patientDetailsForm.value['gender'] ){
      return true;
    }
   }  
   return false;
  }



  getSurgeryId(surgeryId:  any){
    if(surgeryId){
      this.surgeryID = surgeryId;
      this.isSurgerySubmitted = true;
    }
  }

  convertCloneToEditSurgery(){
    this.fromCloneSurgery = false;
    this.hidePatientInfo = true;
    this.patientSubmitted = true;
    this.isSurgerySubmitted = true;
    this.fromEditSurgery = true;
    this.patientInfo = "Edit surgery details";
  }
}

