export enum appEvents{
    reloadData = "reloadData"
}

export interface PostOpMeds {
    label: string;
    value: string
}

export enum patientAccessLevel {
   owner = 'owner',
   viewer = 'viewer'
}