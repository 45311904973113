<div
  class="alcon-ore-container"
  [ngClass]="(oreService.isDarkModeEnabled | async) ? 'alcon-ore-container-dark' : 'alcon-ore-container-light'"
>
  <section class="alcon-ore-header">
    <app-alcon-or-header
      [accountName]="surgeryData?.accountName ?? ''"
      (onModeChange)="onModeChange($event)"
      (onExitOR)="onExitOR($event)"
    />
  </section>
  <section
    class="alcon-ore-body-section alcon-ore-border-bottom"
    *ngIf="surgeryData"
  >
    <div class="alcon-ore-title-section alcon-or-experience-section">
      <div class="alcon-ore-title-section-container">
        <h1 class="alcon-ore-title">
          {{ surgeryData.patientFirstName }}
          {{ surgeryData.patientLastName
          }} - <span>{{ surgeryData.surgeryType }}</span>
        </h1>
        <div class="alcon-ore-title-sub-items d-flex">
          <div
            class="alcon-ore-title-sub-item"
            *ngFor="let subItem of subMenuFieldsORE"
          >
            <span class="alcon-ore-title-sub-item-label"
              >{{ subItem.label }}:
            </span>
            <mat-icon
              *ngIf="subItem.showBooleanIcon"
              class="alcon-ore-title-sub-item-icon"
              [ngClass]="surgeryData[subItem.field] === 'No' ? 'alcon-ore-title-sub-item-no-icon' : 'alcon-ore-title-sub-item-yes-icon'"
              [svgIcon]="
                surgeryData[subItem.field] === 'No' ? 'round_minus_nc' : 'round_check_nc'
              "
            ></mat-icon>
            <span class="alcon-ore-title-sub-item-value">{{
              surgeryData[subItem.field]
            }}</span>
          </div>
        </div>
      </div>
      <div class="alcon-ore-clock">
        <alcon-or-room-clock
          *ngIf="!isPastSurgery; else surgeryDateTemplate"
        ></alcon-or-room-clock>
      </div>
    </div>
  </section>
  <section class="alcon-ore-body-section" *ngIf="surgeryData">
    <div class="alcon-ore-body-section alcon-or-experience-section">
      <div class="alcon-ore-body-details">
        <div>
          <div
            class="alcon-ore-body-details-fields"
            *ngFor="let subItem of summaryDetailsORE"
            [ngClass]="{ 'alcon-ore-body-details-field-small': subItem.twoColumnLayout }"
          >
            <span class="alcon-ore-body-details-field-label"
              >{{ subItem.label }}: </span
            >
            <mat-icon
              *ngIf="subItem.showBooleanIcon"
              class="alcon-ore-body-details-item-icon"
              [ngClass]="surgeryData[subItem.field] === 'No' ? 'alcon-ore-body-details-item-no-icon' : 'alcon-ore-body-details-item-yes-icon'"
              [svgIcon]="
                surgeryData[subItem.field] === 'No' ? 'round_minus_nc' : 'round_check_nc'
              "
            ></mat-icon>
            <span class="alcon-ore-body-details-field-value" [innerHTML]="surgeryData[subItem.field]"></span>
            <mat-icon *ngIf="subItem.isRequired && surgeryData[subItem.field]?.includes?.('---')" class="alcon-ore-alert-icon" svgIcon="alert_triangle"></mat-icon>
          </div>
        </div>
        <div class="alcon-ore-patient-notes">
          <span class="alcon-ore-patient-note-label">Patient comments: </span>
          <span class="alcon-ore-patient-note-value name-truncate-in-three-lines">{{
            surgeryData.PatientNote
          }}</span>
        </div>
        <div class="alcon-ore-edit-history">
          <div class="alcon-ore-edit-history-label">Edit history</div>
          <div class="alcon-ore-patient-no-history" *ngIf="surgeryData && surgeryData.editHistory.length === 0">No edit history.</div>
          <div
            class="alcon-ore-edit-history-item"
            *ngFor="let history of surgeryData.editHistory"
          >
            <app-alcon-ore-history-item [history]="history" />
          </div>
        </div>
      </div>
      <div class="alcon-ore-body-timeout">
        <app-alcon-ore-timeout-capture [timeOutForm]="timeOutForm" [eyeLabel]="surgeryData.avatarEyeLabel" [eye]="surgeryData.eye" [primaryIol]="surgeryData.primaryIol"></app-alcon-ore-timeout-capture>
      </div>
    </div>
  </section>
</div>
<div *ngIf="oreService.oreProcessIsLoading">
  <alc-section-loader></alc-section-loader>
</div>

<ng-template #surgeryDateTemplate>
  <div class="alcon-ore-past-suregery">
    <div class="alcon-ore-past-label">Surgery date:</div>
    <div class="alcon-ore-past-value">
      {{ surgeryData?.surgeryDate | date : "MMMM d, y" }}
    </div>
  </div>
</ng-template>
