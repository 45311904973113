import { HttpHeaders } from "@angular/common/http";

 export const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
 export const completeSurgeriesMaxLimit = 100;
 export const recentChangesDaysLimitFromToday = 2.592e+8;// 3 days in milli seconds
 export const PostOpMedicationsList = [
    {label: "Routine", value: "Routine"},
    {label: "Combo", value: "Combo"},
    {label: "1x", value: "1x"},
    {label: "2x", value: "2x"},
    {label: "3x", value: "3x"},
    {label: "Other", value: "Other"}
  ];

export enum oraPreferenceValues  {
  point_five_d_ora = 'point_five_d_ora',
  one_d_ora = 'one_d_ora'
}

export enum primaryDuplicateValues {
  one_duplicate = 'one_duplicate',
  two_duplicates = 'two_duplicates'
}

export const sapSerialNumberLength = 10;

export const  signLanguageList = [
  { label: 'Not Needed',  value: 'Not Needed'}, 
  { label: 'American Sign Language (ASL)',
    value: 'American Sign Language (ASL)' }
];

export const clearanceValues = [

  {label: 'Clearance not needed', value: 'Clearance not needed'},
  {label: 'Clearance needed, not received', value: 'Clearance needed, not received'},
  {label: 'Clearance needed and received', value: 'Clearance needed and received'}

]

export enum DeviceTypes {
  mobile = 'mobile',
  tablet = 'tablet',
  desktop = 'desktop'
}

interface Dimension {
  width: number;
  height: number;
}

interface SignDimensions {
  [DeviceTypes.mobile]: Dimension;
  [DeviceTypes.tablet]: Dimension;
  [DeviceTypes.desktop]: Dimension;
}

export const signDimensions: SignDimensions = {
  [DeviceTypes.mobile]: { width: 200, height: 150 },
  [DeviceTypes.tablet]: { width: 516, height: 150 },
  [DeviceTypes.desktop]: { width: 576, height: 204.8 },
};

export const alconHttpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

export const alconPdfDocHttpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'accept': 'application/pdf'
  }),
  responseType: 'blob' as 'json'
};

export enum preferenceTypes{
  iol = 'IOL' ,
  arrivalTime = 'PREOP_ARRIVALTIME'
}

export const camelCaseExceptionList = [ 'IOL' ]
 