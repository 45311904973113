import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'alc-section-loader',
  templateUrl: './section-loader.component.html',
  styleUrls: ['./section-loader.component.scss']
})
export class SectionLoaderComponent implements OnInit {

  @Input() isLocal!: boolean;
  constructor() {
}

  ngOnInit() {
  }

}
